import iassign from 'immutable-assign'
import _, { cloneDeep } from 'lodash'
import omitDeep from 'omit-deep-lodash'
import React, { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Container, Form, Row } from 'reactstrap'

import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import {
  ProductEsgOptionExclusionListInput,
  ProductEsgPolicy,
  ProductEsgQuery,
  ProductEsgThematicComponentListInput,
  UpdateProductInput,
  useGetProductEsgLookupQuery,
  useProductEsgQuery,
  useUpdateProductEsgMutation,
  useDeleteProductEsgOptionExclusionsMutation,
  useDeleteProductEsgProductVendorsMutation,
  useDeleteProductEsgProductComponentsMutation,
  EsgExclusionType,
  EsgComponentType
} from '../../__generated__/graphql'
import { FormInputField } from '../../helpers/constant'
import { InitialStateFormat, reShapeObject } from '../../helpers/object'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { CheckBoxes, optionProps } from '../ui/CheckBoxes'
import { FormInput } from '../ui/Forms/FormInput'
import PlaceHolder from '../ui/PlaceHolder'
import { assetTerminologyGetter } from '../../helpers/assetTerminology'

const getProcessInput: (assetId: number) => FormInputField[] = assetId => {
  const get_term = assetTerminologyGetter(assetId);
  return [
    { 
      property: "", 
      label: "Process", 
      type: "",
      subClasses: {
        wrapperClasses: "row form-section-title headline underline small-font-size py-2 mb-2"
      }
    },
    {
      property: "productEsgProcess.strategyIncludesExclusionaryScreening",
      label: get_term("Dedicated ESG strategy; ESG considerations are primary objective"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.managedWithEsgConsiderations",
      label: get_term("Not a dedicated ESG strategy; ESG considerations are part of investment framework"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.sfdr8",
      label: get_term("Complies with SFDR Article 8"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.sfdr9",
      label: get_term("Complies with SFDR Article 9"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.cfaDisclosure",
      label: get_term("Product conforms with CFA Institute Global ESG Disclosure Standards"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.useScreens",
      label: get_term("Product utilizes ESG data to constrain the investible universe"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.optionExclusionList",
      label: get_term("Product utilizes ESG data to constrain the investible universe (uses screens)"),
      type: "checkbox",
      subtype: "multiple",
      optionSource: "esgExclusionTypeOptions"
    },
    {
      property:
        "productEsgProcess.securityLevelEsgRiskAndOpportunityProprietaryMonitoring",
      label:
        get_term("Strategy utilizes proprietary analytical tools for monitoring ESG risks and opportunities at the security level"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property:
        "productEsgProcess.portfolioLevelEsgRiskAndOpportunityProprietaryMonitoring",
      label:
        get_term("Strategy utilizes proprietary analytical tools for monitoring ESG risks and opportunities at the portfolio level"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.issuerLevelEsgSubjectiveDecisionMaking",
      label:
        get_term("Strategy evaluates and considers ESG metrics at the issuer level with subjective decision-making (i.e. individual judgment-based)"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.issuerLevelEsgObjectiveDecisionMaking",
      label:
        get_term("Strategy evaluates and considers ESG metrics at the issuer level with objective decision-making (i.e. rules-based)"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.useProprietaryScoringForEsgResearch",
      label:
        get_term("Strategy utilizes proprietary scoring or rating for ESG research"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.monitorsReportsOnGoals",
      label: 
        get_term("Strategy monitors and reports on of achievement of ESG goals at the security level"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.inputAndMethodologyDescription",
      label: get_term("Brief description of inputs and methodology"),
      type: "textarea"
    },
    {
      property:
        "productEsgProcess.directAndMeasurableImpactObjectiveBasedOnAnyOfEsgPillars",
      label:
        get_term("Strategy has direct and measurable impact objectives based on any of the E, S, or G pillars"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property:
        "productEsgProcess.directAndMeasurableImpactObjectiveBasedOnAnyOfEsgPillarsDescription",
      label: get_term("Brief description of strategy"),
      type: "textarea"
    },
    {
      property: "productEsgProcess.strategyDescribedAsThematicForAnyEsgComponent",
      label:
        get_term("Strategy best described as thematic for one or more ESG components (e.g. climate change, renewable energy, carbon emissions, sustainable development goals, etc.)"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property:
        "productEsgProcess.strategyDescribedAsThematicForAnyEsgComponentList",
      label: get_term("Component(s) considered:"),
      type: "checkbox",
      subtype: "multiple",
      optionSource: "esgComponentTypeOptions"
    },
    {
      property: "productEsgProcess.includeForwardLookingEsgMetrics",
      label:
        get_term("ESG process includes forward-looking ESG metrics (e.g. positive momentum in ratings, demonstrated willingness to improve, progress on topics of engagement, etc.)"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property:
        "productEsgProcess.regularlyMateriallyAssessedProcessAcrossSectorAndIndustry",
      label:
        get_term("Process regularly assesses materiality across sectors/industries to account for evolving dynamics"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.fixedIncomeConsiderationOfProceeds",
      label: get_term("Strategy considers use of proceeds (fixed income only)"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property:
        "productEsgProcess.clientReportProductionHighlightingEsgMetricsOfHoldings",
      label:
        get_term("Strategy produces reports to clients that highlight ESG metrics of the holding (impact/scoring/alignment etc.)"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    }
  ]
}

const getThemesInput: (assetId: number) => FormInputField[] = assetId => {
  const get_term = assetTerminologyGetter(assetId);
  return [
    { 
      property: "", 
      label: "Themes", 
      type: "",
      subClasses: {
        wrapperClasses: "row form-section-title headline underline small-font-size py-2 my-2"
      }
    },
    {
      property: "productEsgTheme.alignedWithUNSustainableDevelopmentGoals",
      label:
        get_term("Strategy is specifically aligned with the UN Sustainable Development Goals"),
      type: "radio",
      subtype: "boolean", //typename,
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgProcess.investmentTeamInvolvedProxyVoting",
      label: get_term("Investment team involved in proxy voting"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgTheme.engagedWithIssuerAboutEnvironmentalMatter",
      label:
        get_term("Strategy engaged with an issuer about Environmental matters (e.g., oral or written correspondence directly with issuer management personnel, investor relations, and/or board of directors) in the past year"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgTheme.engagedWithIssuerAboutSocialMatter",
      label:
        get_term("Strategy engaged with an issuer about Social matters (e.g., oral or written correspondence directly with issuer management personnel, investor relations, and/or board of directors) in the past year"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    },
    {
      property: "productEsgTheme.engagedWithIssuerAboutGovernanceMatter",
      label:
        get_term("Strategy engaged with an issuer about Governance matters (e.g., oral or written correspondence directly with issuer management personnel, investor relations, and/or board of directors) in the past year"),
      type: "radio",
      subtype: "boolean",
      subClasses: {
        labelClasses: "col-sm-9",
        inputWrapperClasses: "col-sm-3"
      }
    }
  ]
}

const getProductEsgInput: (assetId: number) => FormInputField[] = (assetId: number) => [
  ...getProcessInput(assetId),
  ...getThemesInput(assetId)
]

const ProductOverviewEsg: React.FC<{ productId: number, auth: Auth }> = props => {
  const { productId, auth } = props

  const { loading, error, data } = useProductEsgQuery({
    variables: { id: productId }
  })
  const {
    loading: lookupLoading,
    error: lookupError,
    data: lookupData
  } = useGetProductEsgLookupQuery()
  if (loading || lookupLoading) {
    return <PlaceHolder />
  }
  if (error || lookupError) {
    return (
      <div>
        <p>
          {(error && error.message) || (lookupError && lookupError.message)}
        </p>
      </div>
    )
  }
  if (!data || !lookupData) {
    return <p>Product Data not found</p>
  }
  const { esgComponentType, esgExclusionType } = lookupData
  const esgExclusionTypeOptions = esgExclusionType?.enumValues as optionProps[]
  const esgComponentTypeOptions = esgComponentType?.enumValues as optionProps[]
  return (
    <Display
      data={data}
      options={{ esgExclusionTypeOptions, esgComponentTypeOptions }}
      productId={productId}
      auth={auth}
    />
  )
}

const PreShapeActions = {
  "productEsgProcess.optionExclusionList": {
    destinationPath: "productEsgProcess.optionExclusionList",
    action: (a: any[]) =>
      a
        ? a.map(el => ({
            ...el,
            isSelected: true
          }))
        : []
  },
  "productEsgProcess.strategyDescribedAsThematicForAnyEsgComponentList": {
    destinationPath:
      "productEsgProcess.strategyDescribedAsThematicForAnyEsgComponentList",
    action: (a: any[]) =>
      a
        ? a.map(el => ({
            ...el,
            isSelected: true
          }))
        : []
  }
}

const getInitialData = (data: any) => {
  const esgPolicy = data.product?.product?.esgPolicy || ({} as ProductEsgPolicy)
  const assetId = data.product?.product?.assetClass?.parent?.id
  let formattedData = {} as ProductEsgPolicy
  if (_.isEmpty(esgPolicy)) {
    formattedData = InitialStateFormat(
      getProductEsgInput(assetId),
      formattedData
    ) as ProductEsgPolicy
  } else {
    formattedData = esgPolicy
      ? (reShapeObject(
          _.cloneDeep(esgPolicy),
          PreShapeActions
        ) as ProductEsgPolicy)
      : ({} as ProductEsgPolicy)
  }
  return formattedData
}

const Display: React.FC<{
  data: ProductEsgQuery
  options: { [name: string]: optionProps[] }
  productId: number
  auth: Auth
}> = ({ data, productId, options, auth }) => {
  const history = useHistory()
  const formattedData = getInitialData(data)
  const [currentState, setState] = useState(
    formattedData as { [name: string]: any }
  )

  const [updateProductEsg] = useUpdateProductEsgMutation()
  const [deleteProductEsgOptionExclusions] = useDeleteProductEsgOptionExclusionsMutation()
  const [deleteProductEsgProductVendors] = useDeleteProductEsgProductVendorsMutation()
  const [deleteProductEsgProductComponents] = useDeleteProductEsgProductComponentsMutation()

  const [initialState, setInitial] = useState(
    formattedData as { [name: string]: any }
  )
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    let {
      optionExclusionList,
      strategyDescribedAsThematicForAnyEsgComponentList
    } = currentState.productEsgProcess
    optionExclusionList = ((optionExclusionList || []) as optionProps[])
      .filter(el => el.isSelected)
      .map(
        el =>
          ({
            exclusionType: el.code
          } as ProductEsgOptionExclusionListInput)
      )
    strategyDescribedAsThematicForAnyEsgComponentList = ((strategyDescribedAsThematicForAnyEsgComponentList ||
      []) as optionProps[])
      .filter(el => el.isSelected)
      .map(el => ({
        componentType: el.code
      })) as ProductEsgThematicComponentListInput
    let oldState = cloneDeep(currentState)
    if(!oldState?.productEsgProcess){
      _.set(oldState, "productEsgProcess", {})
    }
    let formattedState = iassign(
      oldState,
      ["productEsgProcess"],
      state => ({
        ...state || {},
        optionExclusionList,
        strategyDescribedAsThematicForAnyEsgComponentList
      })
    )

    formattedState = _.cloneDeep(formattedState)

    // Rename useScreens from output to useScreen for input
    _.set(formattedState, "productEsgProcess.useScreen", _.get(formattedState, "productEsgProcess.useScreens"))
    _.unset(formattedState, "productEsgProcess.useScreens")

    // Perform list removals
    if (initialState.productEsgProcess.optionExclusionList) {
      const initialOptionExclusionList = initialState.productEsgProcess.optionExclusionList.map((component: any) => component.code)
      const currentOptionExclusionList = formattedState.productEsgProcess.optionExclusionList.map((component: any) => component.exclusionType)
      const optionExclusionListRemovals = _.difference(initialOptionExclusionList, currentOptionExclusionList)

      if (optionExclusionListRemovals.length > 0) {
        deleteProductEsgOptionExclusions({
          variables: {
            input: {
              id: productId,
              exclusionTypes: (optionExclusionListRemovals as EsgExclusionType[])
            }
          }
        })
      }
    }

    if (initialState.productEsgProcess.strategyDescribedAsThematicForAnyEsgComponentList) {
      const initialStrategyDescribedAsThematicForAnyEsgComponentList = initialState.productEsgProcess.strategyDescribedAsThematicForAnyEsgComponentList.map((component: any) => component.code)
      const currentStrategyDescribedAsThematicForAnyEsgComponentList = formattedState.productEsgProcess.strategyDescribedAsThematicForAnyEsgComponentList.map((component: any) => component.componentType)
      const strategyDescribedAsThematicForAnyEsgComponentListRemovals = _.difference(initialStrategyDescribedAsThematicForAnyEsgComponentList, currentStrategyDescribedAsThematicForAnyEsgComponentList)

      if (strategyDescribedAsThematicForAnyEsgComponentListRemovals.length > 0) {
        deleteProductEsgProductComponents({
          variables: {
            input: {
              id: productId,
              componentTypes: (strategyDescribedAsThematicForAnyEsgComponentListRemovals as EsgComponentType[])
            }
          }
        })
      }
    }

    const state = omitDeep(formattedState, "__typename")
    const updateData = {
      id: productId,
      patch: {
        esgPolicy: { ...state }
      }
    } as UpdateProductInput
    updateProductEsg({ variables: { input: updateData } })
      .then(result => {
        setSaving(false)
        const data = result.data?.updateProduct
        if (data) {
          let newData = getInitialData(data) as { [name: string]: any }
          console.log("success")
          setState(newData)
          setInitial(newData)
          setEditMode(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log(err.message)
      })
  }

  const handleCheckboxesChangeToState = (
    input: optionProps[],
    subvalue: string
  ) => {
    let oldState = cloneDeep(currentState)
    if(!oldState["productEsgProcess"]){
      _.set(oldState, ["productEsgProcess"], {})
    }
    let newState
    if (subvalue === "esgExclusionTypeOptions") {
      newState = iassign(
        oldState,
        ["productEsgProcess"],
        process => ({
          ...process || {},
          optionExclusionList: input
        })
      )
      setState(newState)
    } else if (subvalue === "esgComponentTypeOptions") {

      newState = iassign(
        oldState,
        ["productEsgProcess"],
        process => ({
          ...process || {},
          strategyDescribedAsThematicForAnyEsgComponentList: input
        })
      )
      setState(newState)
    }
  }

  const handleInputChange = (value: any, property: string) => {
    let path = property.split(".")
    let oldState = cloneDeep(currentState)
    if(_.get(oldState, path) === undefined){
      _.set(oldState, path, {} as any)
    }
    let newState = iassign(
      oldState,
      path,
      () => value
    )
    setState(newState)
  }

  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }
  const assetId = data.product?.product?.assetClass?.parent?.id || 0
  const productEsgInput = getProductEsgInput(assetId)
  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid className={"px-0"}>
          <Row>
            <Col>
              <div className="pane pane-toolbar sticky-top">
                {auth.checkPermissions(["edit:manager"]) &&
                  <EditButtons 
                    editMode={editMode} 
                    setEditMode={setEditMode} 
                    saving={saving} 
                    onSubmit={handleSubmit}
                    cancelEdit={() => setState(initialState)}
                  />
                }
              </div>
              <div className="pane mb-4">
                <Row>
                  <Col sm="7" className="px-4">
                    {productEsgInput.map(
                      (
                        {
                          property,
                          label,
                          type,
                          subtype,
                          placeholder,
                          optionSource,
                          subClasses
                        },
                        idx
                      ) => {
                        let propertyVal: any = _.get(currentState, property)
                        let onChangeCallback = (value: any) =>
                          handleInputChange(value, property)
                        let hidden: boolean = false
                        if (type && type === "checkbox") {
                          if (subtype === "multiple" && optionSource) {
                            if (optionSource === "esgExclusionTypeOptions") {
                              return (
                                <CheckBoxes
                                  optionSource={options.esgExclusionTypeOptions}
                                  options={propertyVal || []}
                                  cb={(input: optionProps[]) =>
                                    handleCheckboxesChangeToState(
                                      input,
                                      "esgExclusionTypeOptions"
                                    )
                                  }
                                  disabled={!editMode}
                                  key={"esgExclusionTypeOptions"}
                                  label="List Exclusions:"
                                />
                              )
                            } else if (
                              optionSource === "esgComponentTypeOptions"
                            ) {
                              return (
                                <CheckBoxes
                                  optionSource={options.esgComponentTypeOptions}
                                  options={propertyVal || []}
                                  cb={(input: optionProps[]) =>
                                    handleCheckboxesChangeToState(
                                      input,
                                      "esgComponentTypeOptions"
                                    )
                                  }
                                  disabled={!editMode}
                                  key={"esgComponentTypeOptions"}
                                  label="Component(s) considered:"
                                />
                              )
                            }
                          }
                        }
                        return (
                          !hidden && (
                            <div key={idx}>
                              <FormInput
                                key={idx}
                                property={property}
                                displayName={label}
                                subClasses={subClasses}
                                type={type}
                                subtype={subtype}
                                placeholder={placeholder}
                                idx={idx}
                                editMode={editMode}
                                propertyVal={propertyVal}
                                updateValue={onChangeCallback}
                                optionSource={optionSource}
                              />
                            </div>
                          )
                        )
                      }
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}
export default ProductOverviewEsg
