import { get } from "lodash"
import React, { useState } from 'react'

import { Button, Col, Container, Row } from "reactstrap"
import Auth from '../../Auth/Auth'
import { ClientPortfolioDetailComponentFragment, ClientPortfolioDetailLayoutFragment, useProductAnalysisComparisonsQuery } from '../../__generated__/graphql'
import { CalendarPicker } from '../CalendarPicker'
import { ReportDisplayType } from '../Report/Shared/ReportComponent'
import { ReportError } from '../Report/Shared/ReportMain'
import ReportSection from "../Report/Shared/ReportSection"
import ErrorDisplay from '../Shared/ErrorDisplay'
import Loading from '../ui/Loading'

interface ProductAnalysisProps {
  productId: number
  auth: Auth
}

const ProductAnalysis: React.FC<ProductAnalysisProps> = (props) => {
  const {productId} = props

  const {data, loading, error} = useProductAnalysisComparisonsQuery({ variables: { id: productId, draftView: true, liveView: false } })

  if(loading){
    return (
      <>
        <Loading/>
      </>
    )
  }

  if(error){
    return (
      <>
        <ErrorDisplay error={error}/>
      </>
    )
  }

  if(data && data.product?.product?.layout){
    return (
      <ProductAnalysisWithSettings
        {...props}
        layout={data.product?.product?.layout}
      />
    )
  }

  return (
    <div> No data returned </div>
  )
}

interface ProductAnalysisWithSettingsProps extends ProductAnalysisProps {
  layout: ClientPortfolioDetailLayoutFragment
}

const ProductAnalysisWithSettings: React.FC<ProductAnalysisWithSettingsProps> = (props) => {
  const {layout, auth} = props
  const [searchDate, setSearchDate] = useState(get(layout, 'sections[0].components[0].draftSettings.date', ''))
  const [editedDraftLayout, setEditedDraftLayout] = useState<ClientPortfolioDetailLayoutFragment | undefined>(layout || undefined)
  const [reportState, setReportState] = useState({})

  const heading = () => {
    return (
      <div className="pane pane-toolbar sticky-top above-picker">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          hasHistorical={false}
          hasMonthly={false}
        />
        {/* <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin">
          Download PPTX
          <img src='/assets/PPTX.svg' className="ml-2"/>
        </Button> */}
      </div>
    )
  }

  return (
    <Container fluid className="client-report">
      <Row>
        <Col>
          {heading()}
          <div className="pane pt-2">
            {editedDraftLayout?.sections?.map((section, idx) => {
              if(section?.type && section?.components && editedDraftLayout){
                return(
                  <ReportSection
                    key={idx}
                    type={section?.type}
                    components={
                      section?.components as ClientPortfolioDetailComponentFragment[]
                    }
                    view={ReportDisplayType.External}
                    auth={auth}
                    editMode={false}
                    sectionNumber={idx}
                    setEditedDraftLayout={setEditedDraftLayout}
                    editedDraftLayout={editedDraftLayout}
                    setReportState={setReportState}
                    reportState={reportState}
                    overwriteDate={searchDate}
                  />
                )
              }
              return <React.Fragment key={idx} />
            })}
            {!editedDraftLayout && <ReportError error="No components available at this time." />}
            {editedDraftLayout?.sections?.length === 0 && (
              <ReportError error="No components available at this time." />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default ProductAnalysis