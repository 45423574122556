import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { History, Location } from 'history'
import { cloneDeep, compact, find, first, get, uniqBy } from 'lodash'
import React, { useContext, useRef, useState } from 'react'
import { match, Route, Switch } from 'react-router-dom'
import { Button, Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { EditButtonContext } from '../../Context/EditButtonContext'
import { clientDocumentListColumnDef } from "../../helpers/columnDefs"
import { DocumentAccess, File as Document, FileFullDetailsFragment, FileTypeSubTypeMap, Maybe, PlanDocumentsFragment, ReportDocumentsFragment, useClientReportDocumentsQuery, useMeQuery } from '../../__generated__/graphql'
import { AddFileModal, getDocumentPermission } from '../Shared/Document'
import SortableTable from "../Shared/SortableTable"
import PlaceHolder from '../ui/PlaceHolder'
import ClientDocumentDetail from './ClientDocumentDetail'


type idProps = {
  clientId: number
  reportId?: number
  history: History
  location: Location
  auth: Auth
  match: match
}

export type documentTableData = {
  clientId: number
  reportId: number
  associations: string[]
} & FileFullDetailsFragment

const ClientDocumentsList: React.FC<idProps> = ({ clientId, reportId, history, location, auth, match }) => {
  const [addFileModalOpen, setAddFileModalOpen] = useState(false)

  const addFileInputRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState("")

  const { loading, data, error, refetch } = useClientReportDocumentsQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: clientId }
  })
  const { resetErrors } = useContext(EditButtonContext)

  const { loading:userLoading, error:userError, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  const selectDocument = (id:string) => {
    resetErrors()
    history.push(`/clients/${clientId}${!!reportId ? `/${reportId}` : ""}/documents/${id}`)
  }

  const openFileModal = () => {
    // setAddFileModalOpen(!addFileModalOpen)
    addFileInputRef?.current?.click()
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const heading = (
    <>
      <div className="pane pane-toolbar sticky-top above-picker">
        {auth.checkPermissions(["upload:documents"]) &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" onClick={openFileModal} disabled={!data}>
            Add Document
            <FontAwesomeIcon
              icon="plus-circle"
              className="ml-2 text-callan-blue"
            />
          </Button>
        }
        <Form className="mr-2 pr-3 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyDown={handleEnterKeyDown}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
      </div>
    </>
  )

  if ((loading) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading && !!data  && data.org?.__typename === 'Client'  && data.org.documents && data.org.report) {
    const report = find(data.org.report, ["id", reportId]) as ReportDocumentsFragment
    const associationList = (document:FileFullDetailsFragment) => {
      const managerAssociations = document.managers?.map((m) => m?.name) || []
      const productAssociations = document.products?.map((m) => m?.product?.name) || []
      const vehicleAssociations = document.vehicles?.map((m) => m?.vehicle?.name) || []
      const clientAssociations = document.clients?.map((m) => m?.name) || []
      const planAssociations = document.plans?.map((m) => m?.name) ||[]
      const glidePathAssociations = document.glidePaths?.map((m) => m?.name) || []
      return [...clientAssociations, ...planAssociations, ...managerAssociations, ...productAssociations, ...vehicleAssociations, ...glidePathAssociations] as string[]
    }
    let tableData: documentTableData[]
    if(report){
      tableData = uniqBy(report?.plans?.reduce((result: documentTableData[], entry: Maybe<PlanDocumentsFragment>)=>{
        let plan = cloneDeep(entry) as PlanDocumentsFragment
        const documents = compact(plan?.documents?.map((document) => {return {...document, clientId, reportId, associations: associationList(document as FileFullDetailsFragment) }})) as documentTableData[]
        return [...result, ...documents]
      }, []), 'id')
    } else {
      tableData = uniqBy(compact(get(data, "org.documents", [])?.map((document:FileFullDetailsFragment) => {return {...document, clientId, reportId, associations: associationList(document) }}) as documentTableData[]), 'id')
    }


    let filteredDocuments = tableData?.filter((document: documentTableData) => {
      const permissions = getDocumentPermission({document: document as Document, page: "Client", auth})
      return permissions.view
    })
    let colDef = clientDocumentListColumnDef(auth.checkPermissions(["view:all_documents"]))
    const onReady = (api:any) => {
    }

    return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column">
        <AddFileModal
          inputRef={addFileInputRef}
          associationType={!!report ? "Plan" : "Client"}
          associationId={!!report ? first(report.plans)?.id : data.org.id}
          user={user}
          modalOpen={addFileModalOpen}
          setModalOpen={setAddFileModalOpen}
          setSelectedDocument={selectDocument}
          forceValues={{plan_ids: compact(report?.plans?.map((plan) => plan?.id || 0)), client_ids: [data.org.id]}}
          defaultValues={{access: DocumentAccess._1}}
          auth={auth}
        />
        <Switch>
          <Route
            exact
            path={`${match.url}/:documentId`}
            render={(params) =>
              <ClientDocumentDetail
                allDocuments={filteredDocuments || []}
                filteredDocuments={filteredDocuments || []}
                user={user}
                associationType={!!report ? "Plan" : "Client"}
                auth={auth}
                clientId={clientId}
                reportId={reportId}
                planId={first(report?.plans)?.id}
                history={history}
                location={location}
                match={params.match}
                typeMapping={data.fileTypeMap as FileTypeSubTypeMap[]}
                refetchQuery={refetch}
              />}
            >

          </Route>
          <Route path={`*`}>
            {heading}
              <SortableTable
                loading={loading}
                filterText={search}
                columnDefs={colDef}
                tableData={tableData}
                rowId={"id"}
                onReady={onReady}
              />
          </Route>
        </Switch>

      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}



export default ClientDocumentsList
