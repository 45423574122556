import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import { FormInputSubClasses } from "../../../helpers/constant"
import { getNewStateObject } from "../../../helpers/helpers"
import { FormInput } from "../../ui/Forms/FormInput"

// constants setting here
const EXPLANATION_CHARACTER_LIMIT = 200

type RowDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  optionSource?: string
  tdClass?: string
  charactersLimit?: number
}

interface ManagementPolicyTableItemProps {
  data: any
  rowDefItem: RowDefItem
  highlightRow?: boolean
  idx: number
  row: number
  editMode: boolean
  updateValue: (idx: number, type: string, value: any, property: string) => void
}

type ManagementPolicyTableProps = {
  data: any
  handleChange: (state: any, property: string) => void
  editMode?: boolean
}

export const ManagementPolicyTableRowDef: RowDefItem[] = [
  {
    field: "product.riskManagementPolicy.portfolioLeverage", // open
    type: "float",
    title: "Portfolio Leverage (%)"
  },
  {
    field: "realAssets.propertyLeverage", // ra only
    type: "float",
    title: "Property Leverage (%)"
  },
  {
    field: "product.riskManagementPolicy.hedging", // both
    type: "float",
    title: "Hedging (%)"
  }
]

const ManagementColDef: RowDefItem[] = [
  {
    field: "low",
    type: "float",
    subtype: "percent",
    title: "Low",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    },
    tdClass: ""
  },
  {
    field: "high",
    type: "float",
    subtype: "percent",
    title: "High",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    },
    tdClass: ""
  },
  {
    field: "target",
    type: "float",
    subtype: "percent",
    title: "Target",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    },
    tdClass: ""
  },
  {
    field: "explanation",
    type: "textarea",
    title: "Explanation",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    },
    tdClass: "w-50",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT
  }
]

interface ManagementPolicyTableRowProps {
  data: any
  index: number
  rowDef: RowDefItem
  highlightRow?: boolean
  editMode: boolean
  updateValue: (idx: number, value: any, type: string, property: string) => void
}

const ManagementPolicyTableRow = ({
  data,
  index,
  rowDef,
  editMode,
  updateValue
}: ManagementPolicyTableRowProps) => {
  let field = rowDef.field
  const value = _.get(data, field)
  if (_.isUndefined(value)) {
    return <tr key={`${index}`} className={"invisible"}></tr>
  }
  return (
    <tr key={`${index}`}>
      <td key={`Management-${field}`} className={"text-left"}>
        {rowDef.title}
      </td>
      {ManagementColDef.map((col, colIndex) => {
        return (
          <ManagementPolicyTableItem
            data={value[col.field]}
            rowDefItem={rowDef}
            key={colIndex}
            idx={colIndex}
            row={index}
            editMode={editMode}
            updateValue={updateValue}
          />
        )
      })}
    </tr>
  )
}

const ManagementPolicyTableItem = ({
  data,
  rowDefItem,
  idx,
  row,
  editMode,
  updateValue
}: ManagementPolicyTableItemProps) => {
  let { field, subClasses } = rowDefItem
  let { type, subtype, tdClass, charactersLimit } = ManagementColDef[idx]
  subtype = subtype || ""
  return (
    <td key={`${field}-${idx}`} className={tdClass}>
      <FormInput
        property={field + "." + ManagementColDef[idx].field + "mp"}
        displayName={""}
        type={type}
        subtype={subtype}
        idx={row}
        editMode={editMode}
        propertyVal={data}
        placeholder={type === "number" ? "0" : ""}
        subClasses={subClasses}
        updateValue={value => updateValue(idx, value, type, field)}
        charactersLimit={charactersLimit}
      />
    </td>
  )
}

const ManagementPolicyTable = (seed: ManagementPolicyTableProps) => {
  const tableName = "Management"
  const widthClassName = "col-sm-11"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)

  useEffect(() => {
    setData(seed.data)
  }, [seed.data])

  if (!data) {
    return <div>No data</div>
  }

  const onHandleChangeManagementRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData =
      getNewStateObject({
        state: tableState,
        newValue: value,
        property: `${property}.${ManagementColDef[idx].field}`,
        type
      }) || {}
    setData(newData)
    handleChange(_.get(newData, property), property)
  }

  return (
    <>
      <div
        className={
          "form-section-title headline underline small-font-size w-100 py-2 mb-2"
        }
        key={`${tableName}-0`}
      >
        {`Management Policy`}
      </div>
      <div
        className={`${tableName}-table ${widthClassName} row form-group pl-0`}
      >
        <Table striped hover borderless size="sm" key={`Management-table`} className={"exportable"} data-export-name={"Management Policy"}>
          <thead>
            <tr className="table-header">
              <th key={0}></th>
              {ManagementColDef.map((header, headerIdx) => (
                <th key={headerIdx + 1}>{header.title}</th>
              ))}
            </tr>
          </thead>
          <tbody key={0}>
            {ManagementPolicyTableRowDef.map((rowDef, rowIndex) => {
              return (
                <ManagementPolicyTableRow
                  index={rowIndex}
                  key={rowIndex}
                  data={tableState}
                  rowDef={rowDef}
                  editMode={!!editMode}
                  updateValue={onHandleChangeManagementRow}
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default ManagementPolicyTable
