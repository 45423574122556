import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { History, Location } from 'history'
import { cloneDeep, compact, find, first, sortBy, uniqBy } from 'lodash'
import React, { useState } from 'react'
import { match, Route, Switch } from 'react-router-dom'
import { Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'
import Auth from '../../Auth/Auth'
import { FundManagerDocumentsListColumnDef } from '../../helpers/columnDefs'
import {
  FileTypeSubTypeMap,
  ManagerDocumentFragment,
  Maybe,
  PlanManagerDocumentsFragment,
  useFundManagerDocumentsQuery,
  useMeQuery
} from '../../__generated__/graphql'
import SortableTable from '../Shared/SortableTable'
import PlaceHolder from '../ui/PlaceHolder'
import { getDocumentPermission } from '../Shared/Document'
import ClientDocumentDetail from './ClientDocumentDetail'
import { documentTableData } from './ClientDocumentList'
import { GridReadyEvent } from '@ag-grid-community/core'

type idProps = {
  clientId: number
  reportId: number
  history: History
  location: Location
  auth: Auth
  match: match
}

type fundManagerDocumentTableData = documentTableData & {
  managerNames: string[]
  productNames: string[]
}

/* For manager documents tab under client screen.*/
const FundManagerDocumentsList: React.FC<idProps> = ({ clientId, reportId, history, location, auth, match }) => {
  const [search, setSearch] = useState('')

  const { loading, data, error, refetch } = useFundManagerDocumentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: reportId }
  })

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const { loading: userLoading, error: userError, data: userData } = useMeQuery({ fetchPolicy: 'cache-first' })
  const user = userData?.me || undefined

  const heading = (
    <>
      <div className='pane pane-toolbar sticky-top above-picker'>
        <Form>
          <FormGroup row className='relative m-0 mr-1'>
            <Input
              type='text'
              placeholder='Find by name'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyDown={handleEnterKeyDown}
            />
            <span className='o-88 absolute center-v right-1 pe-none'>
              <FontAwesomeIcon
                icon={['fas', 'search']}
                size='2x'
                className='fontawesome-icon dark-icon-color text-gray-50'
              />
            </span>
          </FormGroup>
        </Form>
      </div>
    </>
  )

  if (loading || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color='danger'>
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!loading && !!data && data.report) {
    const report = data.report
    let tableData: fundManagerDocumentTableData[] = []
    if (report && reportId) {
      const getManagers = (document: ManagerDocumentFragment) =>
        compact(document.managers?.map((m) => m?.name))|| ([] as string[])
      const getProducts = (document: ManagerDocumentFragment) =>
        compact(document.products?.map((m) => m?.product?.name)) || ([] as string[])
      tableData = uniqBy(
        report?.plans?.reduce((result: fundManagerDocumentTableData[], entry: Maybe<PlanManagerDocumentsFragment>) => {
          let plan = cloneDeep(entry) as PlanManagerDocumentsFragment
          const documents: fundManagerDocumentTableData[] = compact(
            plan?.managerDocuments?.map((document: ManagerDocumentFragment | null) => {
              if (!document) return
              return {
                ...document,
                managerNames: getManagers(document),
                productNames: getProducts(document),
                associations: [],
                clientId,
                reportId: reportId
              }
            })
          )
          return [...result, ...documents]
        }, []),
        'id'
      )
    }else {
      console.log("no report")
    }

    let filteredDocuments = tableData?.filter((document: fundManagerDocumentTableData) => {
      const permissions = getDocumentPermission({ document: document as any, page: 'Client', auth })
      return permissions.view
    })

    let colDef = FundManagerDocumentsListColumnDef(auth.checkPermissions(['view:all_documents']))
    const onReady = (event: GridReadyEvent) => {
      event.api!.setFilterModel({
        "type.value": {
          filterType: "set",
          values: ["Performance/Commentary"],
        },
      })
    }
    return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column view-port-80">
        <Switch>
          <Route
            exact
            path={`${match.url}/:documentId`}
            render={(params) =>
              <ClientDocumentDetail
                allDocuments={filteredDocuments || []}
                filteredDocuments={filteredDocuments || []}
                user={user}
                associationType={!!report ? "Plan" : "Client"}
                auth={auth}
                clientId={clientId}
                reportId={reportId}
                planId={first(report?.plans)?.id}
                history={history}
                location={location}
                match={params.match}
                typeMapping={data.fileTypeMap as FileTypeSubTypeMap[]}
                refetchQuery={refetch}
                urlType={"managerdocs"}
                notEditable={true}
              />}
            >
          </Route>
          <Route path={`/`}>
            {heading}
            <SortableTable
              loading={loading}
              filterText={search}
              columnDefs={colDef}
              tableData={tableData}
              rowId={'id'}
              onReady={onReady}
            />
          </Route>
        </Switch>
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

export default FundManagerDocumentsList
