import { History } from 'history'
import { find, first } from 'lodash'
import React from 'react'
import { match, Redirect, Route, Switch } from 'react-router-dom'
import { Col, Container, Row, UncontrolledAlert } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { isCallanUser } from '../../helpers/person'
import { listReportExpanded, expandReportList } from '../../helpers/report'
import { MeFragment, ReportsFragment, useClientReportsQuery, useMeQuery } from '../../__generated__/graphql'
import PlaceHolder from '../ui/PlaceHolder'
import { ReportDisplayType } from '../Report/Shared/ReportComponent'
import ReportMain from '../Report/Shared/ReportMain'

type idProps = {
  clientId: number
  reportId: number
  history: History
  auth: Auth
  match: match
}

const ClientReportDetail: React.FC<idProps> = ({ clientId, reportId, history, auth, match }) => {

  const { loading, data, error } = useClientReportsQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: clientId, liveView: true, draftView: false }
  })

  const { loading:userLoading, error:userError, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined
  const isCallan = userData ? isCallanUser(userData) : false

  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!!data  && data.org?.__typename === 'Client' && data.org.report) {
    const report = find(data.org.report, {"id": reportId}) as ReportsFragment
    const matchedReport = report?.id === reportId
    let showList:listReportExpanded[] | undefined = undefined
    if (isCallan && report?.draftList){
      showList = expandReportList(report.draftList)
    } else if (report?.liveList) {
      showList = expandReportList(report.liveList)
    }

    if(!showList) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <div className='pane pane-table'>
                <UncontrolledAlert color="danger">
                  <h4>Can't find List to view</h4>
                </UncontrolledAlert>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container fluid>
        <Switch>
          <Route
            exact
            path={`${match.url}/:portfolioId`}
            render={(params) =>
              <ReportHolder
                list={showList || []}
                match={params.match}
                clientId={clientId}
                reportId={reportId}
                history={history}
                auth={auth}
                user={user}
                report={report}
              />}
            >
          </Route>
          {matchedReport && (
            <Redirect
              to={`${match.url}/${first(showList)?.id}`}
            />
          )}
          {!matchedReport && (
            <PlaceHolder />
          )}
        </Switch>

      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

interface ReportHolderProps {
  clientId: number
  reportId: number
  history: History
  auth: Auth
  match: {params: {portfolioId?: string}}
  user?: MeFragment
  list: listReportExpanded[]
  report: ReportsFragment
}

function ReportHolder({
  list,
  history,
  user,
  auth,
  clientId,
  reportId,
  match,
  report,
  }: ReportHolderProps) {

  const portfolioId = match.params.portfolioId
  return (
    <Row>
      <ReportMain
        portfolioId={portfolioId ? parseInt(portfolioId) : undefined}
        clientId={clientId}
        reportId={reportId}
        history={history}
        auth={auth}
        list={list}
        user={user}
        view={ReportDisplayType.Live}
        report={report}
      />
    </Row>
  )
}


export default ClientReportDetail
