import React, { useEffect, useState } from "react"
import { History } from 'history'
import { CellClassParams, CellStyle, CellValueChangedEvent, EditableCallbackParams, GridReadyEvent } from "@ag-grid-community/core"
import SortableTable from "../../Shared/SortableTable"
import { Button, Form, FormGroup, Input } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ErrorComponent, LoadingComponent } from "../../Report/Shared/ReportComponent"
import { CallanDateType, Maybe, ReportDueDate, ReportDueDateFragment, ReportDueDateInput, useCallanDateQuery, useReportDueDatesListQuery, useUpdateReportDueDateMutation } from "../../../__generated__/graphql"
import { dateSanityCheck, ReportDueDateColumnDef } from "./ControlCenterColumnDef"
import EditButtons from "../../ui/EditButtons"
import { appDate } from "../../../Context/CalendarContext"
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from "../../../helpers/constant"
import moment from "moment"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { SortModelDirection, SortModelState } from "../../ResearchProducts/FilterViewModal"
import _, {cloneDeep, compact} from "lodash"
import { keys } from "../../../helpers/object"
import RouteLeavingGuard from "../../Shared/RouteLeavingGuard"
import { useHistory } from "react-router-dom"

/** return active reports only*/
const getInitialData = (data: ReportDueDateFragment[] | null | undefined) => {
  if(!data) return null
  let newData = cloneDeep(data).filter(report => !!report.active)
  return newData as  ReportDueDateFragment[]
}

const isCellEditable = (params: EditableCallbackParams | CellClassParams, editMode: boolean) => {
  return editMode && params.colDef.colId === "dueDates"
}

const cssStyleWhenEdit  = (params: CellClassParams, editMode: boolean) => {
  if (isCellEditable(params, editMode)) {
    return { backgroundColor: 'lightBlue' } as CellStyle
  }else {
    return { backgroundColor: '' } as CellStyle
  }
}

const defaultSortModel: SortModelState = [{
  sort: SortModelDirection["asc"],
  colId: "plans",
  sortIndex: 1,
}]

type RowDataTrackerType = {
  [rowId: string]: {
    [columnId: string]: [any, any, any]| string,}
}

enum SavingStatus{
  Initial = 1,
  DeletingDueDates = 2,
  UpdatingDueDates = 3,
  Error = 4,
}

const quarterDate = appDate.format(DATE_API_FORMAT)
const lastQuarterDate = moment(appDate).subtract(3,'months').endOf('quarter').format(DATE_API_FORMAT)

const generateMutationInput = (rowDataTracker: RowDataTrackerType) => {
  let ids = keys(rowDataTracker)
  let result = ids.map((key) => {
    let row = rowDataTracker[key]
    // update dueDate, delete the old, add the new.
    let [oldDueDate, newDueDate, prevDueDate] = row.dueDates as [any, any, any]
    if(newDueDate === oldDueDate) return null
    let add: ReportDueDateInput[] = []
    let remove: string[] = []
    if(oldDueDate) remove.push(quarterDate)
    if(newDueDate) {
      add.push({
        dueDate: newDueDate,
        quarterDate,
      })
    }
    let input = {id: parseInt(key), patch: {} as any}
    if(add.length > 0 && remove.length > 0) {
      input.patch.dueDate = { add, remove, prevDueDate }
    }else if(add.length) {
      input.patch.dueDate = { add }
    }else if (remove.length) {
      input.patch.dueDate = { remove }
    }else {
      return null
    }
    return input
  })
  return compact(result)
}

const getPrevDueDate = (data: ReportDueDateFragment) => {
  let prevDueDates = data.previousDueDates
  return (prevDueDates? prevDueDates[0] : {quarterDate: lastQuarterDate, dueDate: null})?.dueDate
}

const isSaving = (savingStatus: SavingStatus) => {
  return ![SavingStatus.Initial, SavingStatus.Error].includes(savingStatus)
}

const ReportDueDatesComponent: React.FC<any> = (props) => {
  const {user, auth} = props
  const name = ""
  const [editMode, setEditMode] = useState(false)
  const [search, setSearch] = useState("")
  const history: History = useHistory()
  const [gridReadyEvent, setGridApi] = useState<Maybe<GridReadyEvent>>(null)
  const [dataState, setDataState] = useState<ReportDueDateFragment[] | null>(null)
  const [originDataState, setOriginDataState] = useState<ReportDueDateFragment[] | null>(null)
  const [rowDataTracker, setRowDataTracker] = useState<RowDataTrackerType>({})

  const [savingStatus, setSavingStatus] = useState(SavingStatus.Initial)
  const [savingCallCount, setSavingCallCount] = useState(0)
  const [errorMessages, setErrorMessage] = useState<string[]>([])

  const { loading, data, error, refetch } = useReportDueDatesListQuery({
    fetchPolicy: "no-cache",
    variables: {
      quarterDate,
      lastQuarterDate
    }
  })

  const { loading: reportDateLoading, data: reportDate} = useCallanDateQuery({variables: {type: CallanDateType.CMS_CURRENT_MONTH}})

  const [updateDueDate] = useUpdateReportDueDateMutation()

  const earlyReturn = () => {
    setSavingStatus(SavingStatus.Initial)
    setEditMode(false)
  }

  useEffect(() => {
    if(data) {
      let newData = getInitialData(data.reports)
      setDataState(newData)
      setOriginDataState(getInitialData(data.reports))
    }
  }, [data])

  useEffect(() => {
    let saving = isSaving(savingStatus)
    if(savingCallCount === 0 && saving) {
      console.log(141, "saving finished")
      refetch().then(() =>{
        earlyReturn()
        setErrorMessage([])
        setRowDataTracker({})
      })
    }else if (isSaving(savingStatus)) {
      console.log(144, {savingCallCount, savingStatus})
    }
  }, [savingCallCount])

  useEffect(() => {
    updateErrorMessages(rowDataTracker)
  }, [rowDataTracker])

  const updateErrorMessages = (rowDataTracker: RowDataTrackerType) => {
    let rowIds = keys(rowDataTracker)
    if(!rowIds.length) {
      setErrorMessage([])
      return
    }
    let errorRows = rowIds.filter((rowId) => {
      let row = rowDataTracker[rowId]
      let [oldDueDate, newDueDate, prevDueDate] = row.dueDates as [any, any, any]
      return (newDueDate !== oldDueDate) && !dateSanityCheck(newDueDate, {min: prevDueDate || ""})
    })
    let messages = errorRows.map((rowId) => {
      let row = rowDataTracker[rowId]
      let [oldDueDate, newDueDate, prevDueDate] = row.dueDates as [any, any, any]
      let dueDate = moment(newDueDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)
      let prev = moment(prevDueDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)
      return `report ${row?.title || rowId} due date ${dueDate} is not valid. It should be no earlier than ${prev}.`
    })
    setErrorMessage(messages)
  }

  const storeEdit = (rowId: string, title: string, columnId: string, originValue: any, newValue: any, dataConstraints?: {min?: string, max?: string}) =>{
    let state = cloneDeep(rowDataTracker)
    if(!state[rowId]){
     state[rowId] = {}
    }
    state[rowId][columnId] = [originValue, newValue, dataConstraints?.min]
    if(!state[rowId]["title"]) {
      state[rowId]["title"] =  title
    }
    setRowDataTracker(state)
    updateErrorMessages(rowDataTracker)
  }

  const colDef = ReportDueDateColumnDef({quarterDate, lastQuarterDate, editMode, dateConstraints: {min: reportDate?.callanDate || ""}})
  const onReady = (params: GridReadyEvent) => {
    let {columnApi} = params
    setGridApi(params)
    if(columnApi) {
      columnApi.applyColumnState({ state: defaultSortModel })
    }
  }

  const onExport = () => {
    if(!gridReadyEvent?.api) return
    gridReadyEvent.api!.exportDataAsCsv()
  }

  const handleEdit = () => {
    let originData = getInitialData(data?.reports) || []
    gridReadyEvent?.api?.setRowData(originData) 
    setDataState(originData)
    setRowDataTracker({})
    setErrorMessage([])
    setEditMode(!editMode)
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const onSubmit = () => {
    if(errorMessages?.length) {
      let messages = errorMessages.join(" \n ")
      alert("ERROR: \n" + (messages))
      return 
    }
    let inputsBeforeCheck = generateMutationInput(rowDataTracker)
    if(!inputsBeforeCheck.length) {
      console.log("early return")
      earlyReturn()
      return
    }

    let inputs = inputsBeforeCheck.map(({id, patch}) => {
      let {add, remove} = patch.dueDate
      return {id, dueDate: {add, remove}}
    })
    setSavingStatus(SavingStatus.UpdatingDueDates)
    let totalCalls = 0
    inputs.forEach((input) => {
      totalCalls += 1
      updateDueDate({
        variables: input
      }).then(() => {
      }).catch(err => {
        console.error("Error updating DueDate:", err.message)
      }).finally(() => {
        setSavingCallCount((savingCallCount) => savingCallCount - 1)})
    })
    setSavingCallCount((savingCallCount) => savingCallCount < 0 ? savingCallCount + totalCalls : totalCalls)
  }

  if(loading || reportDateLoading) return <LoadingComponent name={name}/>
  if(error) return <ErrorComponent name={name} error={error?.message}/>
  if(dataState?.length === 0) return <ErrorComponent name={name} error={"No data"}/>

  //Need ability for consultant (or office admin), which auth permission ??  
  let exportPermission = true
  let editPermission = auth.checkPermissions(["edit:report_due_dates"])
  const showExportButton = !editMode && exportPermission
  let showIcon = false
  let exportButton = (exportPermission && 
    <div className="d-flex align-items-center justify-content-between pl-1" id="exportReportDueDatesContainer">
      <Button className="pr-1 text-callan-blue pb-2" color="light btn-thin" id="exportReportDueDatesTooltip"
        onClick={()=>{
          console.log("download triggered",)
          onExport()
          }}>
        <span>Export</span>
        <FontAwesomeIcon size="sm" icon="download" className="mx-2 text-callan-blue"/>
        {showIcon && <FontAwesomeIcon icon={"question-circle" as IconName} size="sm"/>}
      </Button>
    </div>)
  return (
    <>
      <RouteLeavingGuard
          when={editMode}
          navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top above-picker w-100">
        <Form className="mr-2 pr-2" onKeyDown={handleEnterKeyDown}>
        {/* <Form className="mr-2 pr-2"> */}
          <FormGroup row className="relative m-0">
            <Input
              type="text"
              placeholder="Find plan or report"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              className="mid-search"
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        {showExportButton && exportButton}
        {editPermission && <EditButtons editMode={editMode} setEditMode={setEditMode} saving={isSaving(savingStatus)} onSubmit={onSubmit} cancelEdit={handleEdit} />}
      </div>
        <SortableTable
          loading={loading}
          filterText={search}
          columnDefs={colDef}
          tableData={dataState}
          onReady={onReady}
          columnTypes={{
            editableColumn: {
              editable: (params: EditableCallbackParams ) => isCellEditable(params, editMode),
              cellStyle: (params: CellClassParams) => cssStyleWhenEdit(params, editMode),
            },
          }}
          editMode={editMode}
          suppressExport={{csv: editMode, excel: editMode}}
          rowId={"id"}
          onCellValueChanged={(params: CellValueChangedEvent) => {
            if(params.oldValue?.toString()!== params.newValue?.toString()){
              // save dueDate edit originValue, newValue to rowDataTracker
              if(params.column?.getColId() === "dueDates") {
                let dueDates = originDataState?.find((row) => row.id === params.data?.id)?.dueDates
                let originOldValue = dueDates? (dueDates[0] as ReportDueDate)?.dueDate: ""
                let prevDueDate = getPrevDueDate(params.data)
                storeEdit(params.data?.id, params.data?.name,"dueDates", originOldValue, params.newValue, {min: prevDueDate})
              }
            }
          }}
        />
    </>
  )
}

export default ReportDueDatesComponent