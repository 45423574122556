import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { find } from 'lodash'
import React from 'react'
import { Redirect, RouteComponentProps, Switch, useHistory } from 'react-router-dom'
import { BreadcrumbProps, Button, Col, Container, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledDropdown } from 'reactstrap'

import { appProps } from '../../queries/extended/appProps'
import { CrumbRoute } from '../../routes/crumb-route'
import { ClientOverviewFragment, ReportOverviewFragment } from '../../__generated__/graphql'
import ClientDocumentList from './ClientDocumentList'
import ClientInteractions from './ClientInteractions'
import ClientPortfolioList from './ClientPortfolioList'
import ClientReportDetail from './ClientReportDetail'
import FundManagerDocumentsList from './FundManagerDocumentList'


interface ClientRouteProps
  extends RouteComponentProps<{
    // To silent typescript on match.params.managerId
    reportId: string
    type: string
}> {}

interface ClientOverviewDetailFragment {
  data: ClientOverviewFragment
  clientId: number
}

const getActiveTab = (props: any, url: string) => {
  if (props.match.url.indexOf(`${url}/report`) === 0) {
    return 'Fund Profile'
  }
  if (props.match.url.indexOf(`${url}/documents`) === 0) {
    return 'Fund Documents'
  }
  if (props.match.url.indexOf(`${url}/portfolios`) === 0) {
    return 'Manager Data'
  }
  if (props.match.url.indexOf(`${url}/managerdocs`) === 0) {
    return 'Manager Documents'
  }
  return 'Menu'
}

const ClientFund: React.FC<appProps &
  ClientRouteProps &
  ClientOverviewDetailFragment> = props => {
  const history = useHistory()
  // const params = useParams() as { subtype?: string }
  // const match = useRouteMatch() || props.match

  const { match, auth } = props
  const {data} = props

  const { type, reportId: reportIdString } = match.params
  const reportId = parseInt(reportIdString)
  const urlWithoutType = type
    ? match.url.slice(0, -type.length - 1)
    : match.url

  // let urlWithoutSubtype =
  // params && params.subtype
  //   ? match.url.slice(0, -params.subtype.length - 1)
  //   : match.url

  let report: ReportOverviewFragment | undefined
  report = find(data.report, ["id", reportId]) as ReportOverviewFragment
  // if ((data.report?.length || 0) > 0 && !report){
  //   history.replace(`/clients/${data.id}/${first(data.report)?.id}/${type}`)
  // }
  const hasLiveList = !!(report?.liveList && report?.active) || (!!report?.draftList && props.auth.checkPermissions(["view:draft_reports"]))

  return (
    <>
      <Container fluid className='position-relative'>
        <Row>
          <Col>
            <Nav className="sub-nav sub-nav-secondary collapsed" tabs role="group">
              <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
                <DropdownToggle caret>
                  { getActiveTab(props, urlWithoutType) }
                </DropdownToggle>
                <DropdownMenu>
                  { hasLiveList &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/report`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/report`)}
                      >
                        Fund Profile
                      </NavLink>
                    </NavItem>
                  }
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/documents`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/documents`)}
                    >
                      Fund Documents
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/managerdocs`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/managerdocs`)}
                    >
                      Manager Documents
                    </NavLink>
                  </NavItem>
                  {auth.checkPermissions(["view:interactions"]) &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/interactions`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/interactions`)}
                      >
                        Interactions
                      </NavLink>
                    </NavItem>
                  }
                  { hasLiveList &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/portfolios`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/portfolios`)}
                      >
                        Manager Data
                      </NavLink>
                    </NavItem>
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Col>
        </Row>
        {auth.checkPermissions(["view:all_clients"]) && match.url.indexOf(`${urlWithoutType}/report`) === 0 &&
          <Button color="blue-link" className="nav-right-link" onClick={() => history.push(`/reports/${reportId}`)}>
            <span className="pr-1">Report Editor</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        }
      </Container>
      <Switch>
        { hasLiveList &&
          <CrumbRoute
            title="report"
            path={`${urlWithoutType}/report`}
            render={(params: BreadcrumbProps) => <ClientReportDetail {...props} match={params.match} reportId={reportId} />}
          />
        }
        { hasLiveList &&
          <CrumbRoute
            title="portfolios"
            path={`${urlWithoutType}/portfolios`}
            render={() => <ClientPortfolioList {...props} reportId={reportId} />}
          />
        }
        <CrumbRoute
          title="documents"
          path={`${urlWithoutType}/documents`}
          render={(params: BreadcrumbProps) => <ClientDocumentList {...props} match={params.match} reportId={reportId} />}
        />
        <CrumbRoute
          title="managerdocs"
          path={`${urlWithoutType}/managerdocs`}
          render={(params: BreadcrumbProps) => <FundManagerDocumentsList {...props} match={params.match} reportId={reportId} />}
        />
        {auth.checkPermissions(["view:interactions"]) &&
          <CrumbRoute
            title="interactions"
            path={`${urlWithoutType}/interactions/:id([A-Za-z0-9]+)?`}
            render={(params: BreadcrumbProps) => <ClientInteractions {...props} reportId={reportId} />}
          />
        }
        <Redirect to={`${urlWithoutType}/${hasLiveList ? "report" : "documents"}`} />
      </Switch>
    </>
  )
}


export default ClientFund
